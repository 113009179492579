<template>
	<div class="tcontainer">

		<div class="clearfix ttitle">
			<button @click="showAddEdit" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="title">Manage Templates</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column style="width: 200px" field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="title" header="Title" filterField="title" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Title" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['title'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="content" header="Content" filterField="content" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Content"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['content']}}
                    </div>
                </template>
            </Column> -->
			<Column header="Options">
				<template #body="{ data }">
					<div class="tdata options">
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
						<button class="btn btn-sm btn-outline-danger" @click="editId = data['id']; deleteModal.show();"><i
								class="fa fa-trash"></i></button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Template</div>
								<div v-else>Edit Template</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">


							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.title?.hasError && fcontrol.title.touched }"
									v-model="fdata.title" @blur="fcontrol.title.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.title?.hasError">
									<span v-if="fcontrol.title.errors.required">You must enter title</span>
									<span v-if="fcontrol.title.errors.max">Title can contain maximum
										{{ fcontrol.title.errors.max.maxLength }} characters</span>
								</div>
								<label>Title</label>
							</div>

							<div>
								<div style="border: 1px solid #c4c4c4; border-bottom: none; padding: 5px 9px; border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;">
									<b>Findings</b>
								</div>
								<ckeditor :editor="editor" :config="editorConfig" v-model="fdata.content" @ready="onReady" style="border: 1px solid #c4c4c4; border-top: none; background: #fff;">
								</ckeditor>

								<br>
								<br>

								<div style="border: 1px solid #c4c4c4; border-bottom: none; padding: 5px 9px; border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;">
									<b>Impression</b>
								</div>
								<ckeditor :editor="editor" :config="editorConfig" v-model="fdata.impression" @ready="onReady" style="border: 1px solid #c4c4c4; border-top: none; background: #fff;">
								</ckeditor>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
	data() {
		return {
			editor: DecoupledEditor, //ClassicEditor,
			editorData: '<p style="color: red">Content of the editor.</p>',
			editorConfig: {},


			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			addModal: null,
			deleteModal: null,
			lazyParams: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'title': { value: '', matchMode: 'like' },
				'content': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				title: '',
				content: '',
				impression: '',
			},
			fvalidator: {
				title: 'required|max:255',
				content: 'required',
				impression: ''
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
	},
	methods: {
		onReady(editor) {
			// document.body.prepend( editor.ui.view.toolbar.element );
			editor.ui.getEditableElement().parentElement.insertBefore(
				editor.ui.view.toolbar.element,
				editor.ui.getEditableElement()
			);
		},
		putRecord() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;


			if (this.editId) this.fdata.id = this.editId;
			this.post("radiologist/put-template", this.fdata).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		searchDoctor(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoctors = [...this.customers];
				} else {
					this.filteredDoctors = this.customers.filter((country) => {
						return country.name.toLowerCase().startsWith(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		showAddEdit(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			if (row) this.fpopulate(row);
			this.addModal.show();
		},
		deleteRecord() {
			this.delete("radiologist/delete-template/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("radiologist/load-templates", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>
